import React, { Component, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import ItemModel from '../../data/item-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import './rootCategory-items.css';
import ProviderModifiersFilters from './provider-modifiers-filters';
import HCNImages from '../../helpers/hcn-images';
import DropZone from '../drop-zone/drop-zone';
import { v4 as uuidv4 } from 'uuid';
import HCNLoader from '../../helpers/hcn-loader';
import UploadModel from '../../data/upload-model';
import { useDispatch } from 'react-redux';
import Config from '../../Config';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import Multiselect from "multiselect-react-dropdown";
import HotelModel from "../../data/hotel-model";

const AddRootCategoryItem = props => {
    const history = useHistory();
    const [rootCategoryItem, setRootCategoryItem] = useState({
        providerId: FilterModel.providerId,
        name: '',
        price: 0,
        description: '',
        images: [],
        filters: [],
        modifiers: [],
        taxes: [],
        isAlcohol: false,
        meta: {},
        placeSettings: true,
    });
    const isSynergyProvider = FilterModel.provider.communicationType === 3;
    const [uploadedFileName, setUploadedFileName] = useState({});
    const [synergyTaskCodes, setSynergyTaskCodes] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const defaultImageUploadRef = useRef();
    const mainImageUploadRef = useRef();
    const thumbnailImageUploadRef = useRef();
    const mainRef = useRef();

    const dispatch = useDispatch();

    const itemId = props.component
        ? props.component.location.pathname.split('/add-rootCategory-item/')[1]
        : undefined;

    useEffect(() => {
        mainRef.current.scrollIntoView()
        if (itemId) {
            setEditMode(true);

            HCNLoader.show(dispatch);
            ItemModel
                .getRootCategoryItems(FilterModel.providerId)
                .then(res => {
                    setRootCategoryItem(res.find(item => item.id === itemId));
                    setUploadedFileName(...res.find(item => item.id === itemId)?.images)
                    HCNLoader.hide(dispatch);
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        }
    }, []);

    useEffect(() => {
        if (!isSynergyProvider) return;
        HCNLoader.show(dispatch);
        HotelModel
            .getByProviderId(FilterModel.providerId)
            .then(res => {
                return ItemModel.getSynergyTaskCodesBySynergyPID(res.meta?.synergyPID)
            })
            .then(res => {
                res && res.forEach(elem => { elem.displayValue = `${elem.description} - ${elem.code}`})
                setSynergyTaskCodes(res);
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    }, [isSynergyProvider]);

    const createRootCategoryItem = () => {
        if (rootCategoryItem.name && rootCategoryItem.price && rootCategoryItem.images.length > 0) {
            // Add validation for Synergy task code
            if (isSynergyProvider && !rootCategoryItem.meta?.synergyTaskCode) {
                toast(getTranslatedText('synergyTaskCodeRequired', store), {type: "error"});
                return;
            }

            let updatedRootCategoryItem = {
                name: rootCategoryItem.name,
                price: parseFloat(rootCategoryItem.price),
                description: rootCategoryItem.description,
                images: rootCategoryItem.images,
                filters: rootCategoryItem.filters.map(f => typeof f === 'string' || f instanceof String ? f : f.id),
                modifiers: rootCategoryItem.modifiers.map(m => typeof m === 'string' || m instanceof String ? m : m.id),
                taxes: rootCategoryItem.taxes.map(t => typeof t === 'string' || t instanceof String ? t : t.name),
                upsells: rootCategoryItem.upsells ? rootCategoryItem.upsells.map(t => t.id) : [],
                isAlcohol: rootCategoryItem.isAlcohol,
                meta: rootCategoryItem.meta,
                placeSettings: rootCategoryItem.placeSettings,
                providerId: rootCategoryItem.providerId,
                available: true,
            };

            if (editMode) {
                HCNLoader.show(dispatch);
                ItemModel
                    .updateItem(updatedRootCategoryItem, rootCategoryItem.id)
                    .then(() => {
                        HCNLoader.hide(dispatch);
                        history.push('/rootCategory-items');
                    })
                    .catch(err => {
                        toast(err.message);
                        HCNLoader.hide(dispatch);
                    });
            } else {
                rootCategoryItem.available = true;

                HCNLoader.show(dispatch);
                ItemModel
                    .createItem(updatedRootCategoryItem)
                    .then(() => {
                        HCNLoader.hide(dispatch);
                        history.push('/rootCategory-items');
                    })
                    .catch(err => {
                        toast(err.message);
                        HCNLoader.hide(dispatch);
                    });
            }
        } else {
            toast(getTranslatedText('addNamePriceImg', store))
        }
    };

    const onFilesUpload = (files, size) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            let filename = `${uuidv4()}.${files[0].name.split('.').pop()}`;
            formData.append(
                'file',
                files[0],
                filename,
            );
            formData.append(
                'name',
                filename,
            );

            HCNLoader.show(dispatch);
            UploadModel
                .upload(formData)
                .then(() => {
                    HCNLoader.hide(dispatch);
                    setUploadedFileName({
                        ...uploadedFileName,
                        [size]: filename,
                    });
                    rootCategoryItem.images = HCNImages.updateImageObject(rootCategoryItem.images, size, filename);
                    setRootCategoryItem({ ...rootCategoryItem });
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        } else {
            console.log('err');
        }
    };

    const deleteImage = (size) => {
        setRootCategoryItem({
            ...rootCategoryItem,
            images: size === 'default'
                ? [{ ...rootCategoryItem.images[0]?.main && { main: rootCategoryItem.images[0]?.main }, ...rootCategoryItem.images[0]?.thumbnail && { thumbnail: rootCategoryItem.images[0]?.thumbnail } }]
                : size === 'main'
                    ? [{ ...rootCategoryItem.images[0]?.default && { main: rootCategoryItem.images[0]?.default }, ...rootCategoryItem.images[0]?.thumbnail && { thumbnail: rootCategoryItem.images[0]?.thumbnail } }]
                    : size === 'thumbnail'
                        ? [{ ...rootCategoryItem.images[0]?.main && { main: rootCategoryItem.images[0]?.main }, ...rootCategoryItem.images[0]?.default && { thumbnail: rootCategoryItem.images[0]?.default } }]
                        : [],
        })

        setUploadedFileName({ ...uploadedFileName, [size]: null })
    }

    const getSelectedSynergyTaskCode = () => {
        const task = synergyTaskCodes.length !== 0 && synergyTaskCodes.find(task => task.code == rootCategoryItem.meta?.synergyTaskCode);
        return task ? [task] : [];
    }

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site" ref={mainRef}>
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <h1 className="main-title">{editMode ? 'Edit' : 'Add New'} Item</h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    <button onClick={() => history.push('/rootCategory-items')} type="submit" className="btn">
                                        {getTranslatedText('cancel', store)}
                                    </button>
                                    <button onClick={createRootCategoryItem} type="submit" className="btn default-btn">
                                        {getTranslatedText('save', store)}
                                    </button>
                                    <MainMenuBtn />
                                </div>
                            </div>

                        </div>
                    </header>
                    <main className="spacing">
                        <div className="info info-title" data-info="item_name">
                            <label htmlFor="item_name">{getTranslatedText('name', store)} <sup>(*)</sup></label>
                            <input
                                id="item_name"
                                name="item_name"
                                type="text"
                                value={rootCategoryItem?.name}
                                onChange={event =>
                                    setRootCategoryItem({
                                        ...rootCategoryItem,
                                        name: event.target
                                            .value,
                                    })}
                            />
                        </div>
                        <div className="info info-title" data-info="item_desc">
                            <label htmlFor="item_desc">{getTranslatedText('description', store)}</label>
                            <input
                                id="item_desc"
                                name="item_desc"
                                type="text"
                                value={rootCategoryItem?.description}
                                onChange={event =>
                                    setRootCategoryItem({
                                        ...rootCategoryItem,
                                        description: event.target
                                            .value,
                                    })}
                            />
                        </div>
                        { isSynergyProvider && <div className="info info-title" data-info="item_desc" style={{ marginTop: 30 }} >
                            <label htmlFor="item_synergy_task">{getTranslatedText('synergyTaskCode', store)} <sup>(*)</sup></label>
                            <Multiselect
                                id="item_synergy_task"
                                name="item_synergy_task"
                                selectedValues={getSelectedSynergyTaskCode()}
                                style={{

                                    multiselectContainer: {  width: '400px', },
                                    searchBox: {
                                        border: 'none',
                                        'border-bottom': '1px solid #1793c2',
                                        'border-radius': '0px',
                                        width: '400px',
                                    }
                                }}
                                options={synergyTaskCodes}
                                avoidHighlightFirstOption={true}
                                placeholder='Search tasks'
                                displayValue="displayValue"
                                selectionLimit={1}
                                onSelect={(_, item) => setRootCategoryItem({ ...rootCategoryItem, meta: Object.assign(rootCategoryItem.meta, { synergyTaskCode: item.code }) })}
                                onRemove={() => {
                                    const updatedMeta = { ...rootCategoryItem.meta };
                                    delete updatedMeta.synergyTaskCode;
                                    setRootCategoryItem({ ...rootCategoryItem, meta: updatedMeta });
                                }}
                            />
                        </div>
                        }

                        <div className="info info-title" style={{ width: 150 }} data-info="item_price">
                            <label htmlFor="item_price">{getTranslatedText('price', store)} <sup>(*)</sup></label>
                            <input
                                id="item_price"
                                name="item_price"
                                type="number"
                                value={!rootCategoryItem?.price ? '' : Math.abs(rootCategoryItem?.price)}
                                defaultValue={null}
                                min={0}
                                max={1000}
                                onChange={event => {
                                    event && setRootCategoryItem({ ...rootCategoryItem, price: null })
                                    setRootCategoryItem({
                                        ...rootCategoryItem,
                                        price: event.target.value > 1000 ? 1000 : event.target.value,
                                    })
                                }
                                }
                            />
                        </div>
                        <section className="main-section">
                            {Object.values(Config.IMAGE_SIZES).map((size, index) =>
                                <div className="upload" key={index}>
                                    <label><h3>{size === Config.IMAGE_SIZES.DEFAULT ? getTranslatedText('defaultImage', store) : size === Config.IMAGE_SIZES.MAIN ? getTranslatedText('mainImage', store) : getTranslatedText('Image', store)}</h3></label>
                                    {rootCategoryItem && rootCategoryItem.images && rootCategoryItem.images.length > 0 && size in rootCategoryItem.images[0] &&
                                        <div className="uploaded-photo">
                                            <div className='grid-flex'>
                                                <img src={HCNImages.getFullImageURL(rootCategoryItem.images[0], size)} width="200" />
                                                <button className="remove margin-left-auto" onClick={() => deleteImage(size)}>
                                                    <img src="../img/svg/trash.svg" alt="double item" />
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {!uploadedFileName?.[size] &&
                                        <DropZone
                                            accepted_mime_types={'image/gif,image/jpeg,image/png,image/jpg'}
                                            text={getTranslatedText('ImagdragDrop', store)}
                                            multiple={false}
                                            onFilesUpload={files => onFilesUpload(files, size)}
                                            ref={size === Config.IMAGE_SIZES.DEFAULT ? defaultImageUploadRef : size === Config.IMAGE_SIZES.MAIN ? mainImageUploadRef : thumbnailImageUploadRef}
                                        />
                                    }
                                </div>,
                            )}
                            <div style={{ marginTop: 30 }}>
                                <span>{getTranslatedText('isAlcohol', store)}</span>
                                <input
                                    type='checkbox'
                                    checked={rootCategoryItem.isAlcohol}
                                    onClick={event => setRootCategoryItem({ ...rootCategoryItem, isAlcohol: event.target.checked })}
                                />
                            </div>

                            <ProviderModifiersFilters rootCategoryItem={rootCategoryItem} setRootCategoryItem={setRootCategoryItem} itemId={itemId} editMode={editMode} />
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AddRootCategoryItem;
