import Config from '../Config';
import Services from './services';

const HotelModel = {
    remote_getAll: () => {
        return Services.getApi(Config.getHotelPath() + 'properties');
    },
    getByProviderId: (providerId) => {
        return Services.getApi(Config.getBaseAdminPath() + '/properties/providers/' + providerId);
    },
}

export default HotelModel
