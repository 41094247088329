import Config from '../Config';
import Services from './services';

const ItemModel = {
    createItem: (item) => {
        return Services.postApi(Config.getBasePath() + '/items', item);
    },
    updateItem: (item, id) => {
        return Services.putApi(Config.getBasePath() + `/items/${id}`, item);
    },
    getItemsUpsells: (id) => {
        return Services.getApi(Config.getBasePath() + `/items/${id}/upsells`);
    },
    getRootCategoryItems: (id) => {
        return Services.getApi(Config.getBasePath() + `/items?providerId=${id}`);
    },
    getRootCategoryCategoryItems: (id) => {
        return Services.getApi(Config.getBasePath() + `/categories/${id}/items`);
    },
    getSynergyTaskCodesBySynergyPID: (pid) => {
        return Services.getApi(Config.getBasePath() + `/items/${pid}/synergy/task-codes`);
    },
    deleteRootCategoryCategoryItem: (categoryId, itemId) => {
        return Services.deleteApi(Config.getBasePath() + `/categories/${categoryId}/items/${itemId}`);
    },
    deleteRootCategoryItem: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/items/${id}`);
    },
};
export default ItemModel;
