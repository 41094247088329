import React, { Component, useRef } from "react";
import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router-dom";
import ProviderModel from "../../data/provider-model";
import MainMenuBtn from "../menu-settings/main-menu-btn";
import SideBar from "../side-bar/side-bar";
import FirstTab from "./tabs/FirstTab";
import SecondTab from "./tabs/secondTab";
import Tabs from "./tabs/tabs";
import { useDispatch } from "react-redux";
import HCNLoader from "../../helpers/hcn-loader";
import { ToastContainer, toast } from "react-toastify";
import { getTranslatedText } from "../../helpers/checkTranslations";
import { store } from "../../redux/store";
import Config from "../../Config";
import { validateEmails } from "../../utils/functions";
const ProviderInfo = (props) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("tab1");
  const [modified, setModified] = useState(false);
  const [actionText, setActionText] = useState("Add");

  const first = useRef(null);

  useEffect(() => {
    first.current.scrollIntoView();
  }, []);

  const param = props.component
    ? props.component.location.pathname.split("/provider-info/")[1]
    : undefined;
  const providerDefaultData = {
    id: undefined,
    name: "",
    info: "",
    teaser: "",
    type: -1,
    updated: "",
    images: [],
    timezone: "",
    deliveryTime: 15,
    communicationType: 0,
    tips: [],
    fees: {
      fees: {
        fees: [],
        taxes: [],
        extraTaxes: [],
      },
    },
    deviceToken: "",
    providerType: Config.getDineIn() ? 1 : 2,
    meta: {},
  };

  const [providerForm, setProviderForm] = useState(providerDefaultData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (param) {
      let values = param.split("/");
      HCNLoader.show(dispatch);
      ProviderModel.remote_getOne(values[0])
        .then((res) => {
          console.log({ res });
          setProviderForm({
            ...res,
            id: res.id && !values[1] ? res.id : undefined,
            name: res.name ? res.name : "",
            info: res.info ? res.info : "",
            teaser: res.teaser ? res.teaser : "",
            images: res.images ? res.images : [],
            type: res.type ? res.type : 0,
            fees: res.fees ? res.fees : {},
            timezone: res.timezone ? res.timezone : "",
            deliveryTime: res.deliveryTime ? res.deliveryTime : "",
            tips: res.tips ? res.tips : [],
            placeSettings: res.placeSettings ? res.placeSettings : false,
            communicationType: res.communicationType
              ? res.communicationType
              : 0,
            // todo
            meta: res.meta || [],
            deviceToken: res.deviceToken,
            providerType: Config.getDineIn() ? 1 : 2,
          });
          setTimeout(() => setModified(false), 100);
          HCNLoader.hide(dispatch);
        })
        .catch((err) => {
          toast(err.message);
          HCNLoader.hide(dispatch);
        });
      setActionText("Edit");
    } else {
      setProviderForm(providerDefaultData);
      setTimeout(() => setModified(false), 100);
      setActionText("Add");
    }
  }, []);

  useEffect(() => {
    if (!param) {
      setProviderForm(providerDefaultData);
      setTimeout(() => setModified(false), 100);
      setActionText("Add");
    }
  }, [param]);

  const addProvider = (providerForm) => {
    console.log(1111);
    if (modified) {
      HCNLoader.show(dispatch);
      ProviderModel.remote_addOne({
        ...providerForm,
      })
        .then(() => {
          HCNLoader.hide(dispatch);
          history.push("/providers");
        })
        .catch((err) => {
          toast(err.message);
          HCNLoader.hide(dispatch);
          toast.error(getTranslatedText("addAllFields", store));
        });
    } else {
      toast.error(getTranslatedText("changeBeforeSave", store));
    }
  };

  const upDateProvider = (providerForm) => {
    if (
      providerForm.name !== "" &&
      providerForm.type !== "" &&
      providerForm.teaser !== "" &&
      providerForm.timezone !== "" &&
      providerForm.deliveryTime !== "" &&
      providerForm.info !== "" &&
      providerForm.fees.fees?.length > 0
    ) {
      HCNLoader.show(dispatch);
      ProviderModel.remote_editOne({
        ...providerForm,
        providerType: Config.getDineIn() ? 1 : 2,
      })
        .then(() => {
          HCNLoader.hide(dispatch);
          history.push("/providers");
        })
        .catch((err) => {
          toast(err.message);
          HCNLoader.hide(dispatch);
          toast.error(getTranslatedText("addAllFields", store));
        });
    } else {
      toast.error(getTranslatedText("addAllFields", store));
    }
  };

  const onSubmitForm = () => {
    let emails;
    if (providerForm.communicationType === 2) {
      if (!providerForm.meta.email || providerForm.meta.email === "") {
        toast(getTranslatedText("providerInfoSaveWarningWithEmail", store), {
          autoClose: 4000,
        });
        return;
      }

      emails = validateEmails(providerForm.meta.email)
      if (!emails) {
        toast(getTranslatedText("providerInfoSaveWarningWithEmail", store), {
          autoClose: 4000,
        });
        return;
      }
    }

    if (
      providerForm.name !== "" &&
      providerForm.type !== "" &&
      providerForm.teaser !== "" &&
      providerForm.timezone !== "" &&
      providerForm.deliveryTime !== "" &&
      providerForm.info !== "" &&
      (providerForm.images?.[0]?.default ||
        providerForm.images?.[0]?.main ||
        providerForm.images?.[0]?.thumbnail) &&
      providerForm.fees.fees
    ) {
      providerForm.active = true;
      providerForm.id
        ? upDateProvider({
            ...providerForm,
            name: String(providerForm.name)
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" "),
            providerType: Config.getDineIn() ? 1 : 2,
						email: emails
          })
        : addProvider({
            ...providerForm,
            meta:
              Object.keys(providerForm.meta).length === 0
                ? {
                    paymentRequired: false,
                    selfDelivered: false,
                    ...providerForm.meta,
                  }
                : !providerForm.meta.paymentRequired &&
                  providerForm.meta.selfDelivered
                ? {
                    paymentRequired: false,
                    selfDelivered: providerForm.meta.selfDelivered,
                    ...providerForm.meta,
                  }
                : providerForm.meta.paymentRequired &&
                  !providerForm.meta.selfDelivered
                ? {
                    paymentRequired: providerForm.meta.paymentRequired,
                    selfDelivered: false,
                    ...providerForm.meta,
                  }
                : {
                    paymentRequired: providerForm.meta.paymentRequired,
                    selfDelivered: providerForm.meta.selfDelivered,
                    ...providerForm.meta,
                  },
            name: String(providerForm.name)
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" "),
            providerType: Config.getDineIn() ? 1 : 2,
						email: emails
          });
    } else {
      toast(getTranslatedText("providerInfoSaveWarning", store), {
        autoClose: 4000,
      });
    }
  };

  const cancelAction = () => {
    history.push("/providers");
  };

  return (
    <div ref={first}>
      <ToastContainer />
      <div className="modal-layer"></div>
      <div className="divider grid-flex">
        <SideBar />
        <div id="page" className="site">
          <div className="main-content">
            <div>
              <header className="site-header">
                <div className="flex-between">
                  <h1 className="main-title">
                    {actionText} {getTranslatedText("provider", store)}
                  </h1>
                  <div className="side-functionality">
                    <div className="side-button grid-flex">
                      <button
                        onClick={cancelAction}
                        type="button"
                        className="btn">
                        {getTranslatedText("cancel", store)}
                      </button>
                      <button
                        onClick={onSubmitForm}
                        className="btn default-btn">
                        {getTranslatedText("save", store)}
                      </button>
                      <MainMenuBtn />
                    </div>
                  </div>
                </div>
              </header>
              <main>
                <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
                {activeTab === "tab1" ? (
                  <FirstTab
                    providerForm={providerForm}
                    setProviderForm={setProviderForm}
                    setModified={setModified}
                  />
                ) : (
                  <SecondTab
                    providerForm={providerForm}
                    setProviderForm={setProviderForm}
                  />
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderInfo;
