import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import ProviderModel from '../../data/provider-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import Provider from './provider';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import Config from '../../Config';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';

const Providers = (props) => {
    const [providers, setProviders] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: Config.REDUCER_TYPES.SET_LOADING,
            payload: true,
        });
        ProviderModel.remote_getAll()
            .then(res => {
                if (res && res.length > 0) {
                    setProviders(res.filter(x => Config.getDineIn() ? x.providerType === 1 : x.providerType === 2));
                    dispatch({
                        type: Config.REDUCER_TYPES.SET_LOADING,
                        payload: false,
                    });
                }
            })
            .catch(err => {
                toast(err.message);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            });

        return () => {
            setProviders([]);
        };
    },

        []);

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <h1 className="main-title">
                                {getTranslatedText('providers', store)}
                            </h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    <MainMenuBtn />
                                </div>
                            </div>
                        </div>

                    </header>
                    <main>
                        {providers && providers.map((provider, index) =>
                            <Provider key={index} setProviders={setProviders} providers={providers} provider={provider} />,
                        )}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Providers;
