const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmails = (input) => {

	const inputTrimmed = input.trim()

	const emails = ((inputTrimmed.split(',')).map(it => it.trim())).filter(item => !!(item))


	for (const email of emails) {
		const error = !re.exec(email)

		if (error) {
			return false
		}
	}


	return emails.join(',')

}

export const getQueryParamValue = (url, paramName) => {
	const paramRegex = new RegExp(`[?&]${paramName}=([^&]*)`);
	const match = url.match(paramRegex);
	return match ? decodeURIComponent(match[1]) : null;
}